<template>
  <div>
    <router-link :to="{ name: to, params: {id: id } }">
      <img :src="path" />
    </router-link>
  </div>
</template>

<script>
// v-for="(movie, index) in similar" :key="index"
export default {
  name: "imagebutton",
  props: ["to", "id", "path", "path_without_beginning"]
};
</script>

<style scoped>
img {
  height: 15rem;
  max-width: 15rem;
  border-radius: 5px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
</style>